import React, {useState, useEffect, useMemo, useCallback} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {Pagination} from "react-pagination-bar";
import SearchBar from "../../components/admin-components/SearchBar";
import User from '../../components/admin-components/User'
import {getCountriesApiCall, getUsersApiCall} from "src/utils/apiCalls";
import {useHistory, useLocation} from "react-router-dom";

const USERS_PER_PAGE = 5;

const Creators = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const searchParams = new URLSearchParams(location.search);
    const {status, levelId, countryName, user: searchUser, page} = Object.fromEntries(searchParams.entries());
    const currentPage = parseInt(page, 10) || 1;

    const [totalUserCount, setTotalUserCount] = useState(0);
    const [users, setUsers] = useState([]);
    const [countries, setCountries] = useState([]);

    const access_token = useSelector((state) => state.user.access_token, shallowEqual);

    const handlePageChange = useCallback(
        (pageNumber) => {
            const searchParams = new URLSearchParams(history.location.search);
            searchParams.set('page', pageNumber); // Update the page parameter
            history.push(`${history.location.pathname}?${searchParams.toString()}`);
        },
        [history]
    );

    let config = useMemo(() => {
        return {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
            params: {
                search_string: {status: status, levelId: levelId, countryName: countryName, user: searchUser},
                page: currentPage,
                per_page: USERS_PER_PAGE
            }
        };
    }, [access_token, countryName, currentPage, levelId, searchUser, status]);


    useEffect(() => {
        const getCountries = () => {
            countries?.length === 0 && getCountriesApiCall(config, dispatch, (res) => {
                setCountries(res.data.data)
            })
        }

        getCountries();
    }, [config, countries?.length, dispatch]);

    useEffect(() => {
        getUsersApiCall(config, dispatch, (res) => {
            const data = res.data.data;
            setTotalUserCount(data._meta.total_items);
            setUsers(data.items);
        })
    }, [config, dispatch]);

    return (
        <>
            <AllPromotionsDiv>
                <SearchBar
                    headerText="All Users"
                    isExtraSearch={true}
                    countries={countries}
                />
                {users.map((user) => {
                    return <User user={user} key={user.id} countries={countries}/>;
                })}
            </AllPromotionsDiv>

            {
                totalUserCount !== 0 &&
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "2rem",
                    }}
                    className={"PaginationB"}
                >
                    <StyledMyPagination
                        initialPage={currentPage}
                        itemsPerPage={USERS_PER_PAGE}
                        onPageСhange={handlePageChange}
                        pageNeighbours={2}
                        totalItems={totalUserCount}
                        customClassNames={{
                            rpbItemClassName: "custom-item",
                            rpbItemClassNameActive: "custom-item--active",
                            rpbGoItemClassName: "custom-go-item",
                            rpbItemClassNameDisable: "custom-item--disable",
                            rpbRootClassName: "custom-root",
                        }}
                    />
                </div>
            }
        </>

    );
};

export default Creators;

const AllPromotionsDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 2rem 0 2rem;
`;

const StyledMyPagination = styled(Pagination)`
  .custom-item {
    border-radius: 50px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    min-width: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    cursor: pointer;
    background-color: #180139;
    color: #fff;
  }

  .custom-item--active {
    background-color: rgb(104, 0, 255);
    border-radius: 50px;
    color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    min-width: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .custom-item--disable {
    opacity: 0.5;
  }

  .custom-root ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .custom-go-item {
    align-items: center;
    display: flex;
    font-size: 1rem;
    padding-inline-end: 0.75rem;
    padding-inline-start: 0.75rem;
    width: 150px;
  }

  .custom-go-item input {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    height: 40px;
    width: 100%;
  }

  .custom-progress-bar {
    background-color: #9e75ff;
    border-radius: 5px;
    box-shadow: 0 0 4px rgba(174, 0, 255, 0.4);
    height: 3px;
    margin: 1rem 0;
    max-width: 100%;
    transition: width 0.2s ease;
  }
`;